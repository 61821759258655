import request from '../config/request'

export function getKHS (parameter) {
  return request.post('/akademik/khs', parameter)
}
export function getTranskip (parameter) {
  return request.post('/akademik/transkip', parameter)
}

export function cetTranskip (parameter) {
  return request.get('/download/transkip', { params: parameter, responseType: 'blob' })
}

export function cetKPU (parameter) {
  return request.get('/download/kpu', { params: parameter, responseType: 'blob' })
}

export function cetKHS (parameter) {
  return request.get('/download/khs', { params: parameter, responseType: 'blob' })
}
