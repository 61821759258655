<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card radius-lr20">
          <div class="card-header card-default">
            <div class="row">
              <div class="col-md-6">
                <p id="idExsampel" class="text-muted" >
                  Transkip Mahasiswa
                </p>
              </div>
              <div class="col-md-6">
                <div class="pull-right">
                  <button type="button" v-on:click="downloadTranskip"  class="btn btn-warning btn-rounded btn-sm btn-icon"><i class="fa fa-cloud-download"></i> Download &nbsp;</button> &nbsp;
                  <button type="button" v-on:click="printTranskip"  class="btn btn-primary btn-rounded btn-sm btn-icon"><i class="fa fa-print"></i> Cetak &nbsp;</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body padding-t-0">
            <div class="widget white-bg">
              <div class="row">
                <div class="col-md-4 col-xs-6 b-r"> <strong>{{ profil.nama_lengkap }}</strong>
                  <br>
                  <p class="text-muted">{{ profil.username }}</p>
                </div>
                <div class="col-md-4 col-xs-6 b-r"> <strong>Program Studi</strong>
                  <br>
                  <p class="text-muted">{{ profil.nama_prodi }}</p>
                </div>
                <div class="col-md-2 col-xs-6 b-r"> <strong>Jumlah SKS</strong>
                  <br>
                  <p class="text-muted">{{ jumlahSKS }}</p>
                </div>
                <div class="col-md-2 col-xs-6"> <strong>Nilai IPK</strong>
                  <br>
                  <p class="text-muted">{{ ipk }}</p>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>KODE</th>
                    <th>NAMA MATAKULIAH</th>
                    <th>S K S</th>
                    <th>NILAI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(nil, num) in dataNil" :key="nil.id_nil">
                    <td>{{ num + 1 }}</td>
                    <td>{{ nil.kd_mak }}</td>
                    <td>{{ nil.nm_mak }}</td>
                    <td>{{ nil.mk_sks }}</td>
                    <td>{{ nil.mk_nil }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import printJS from 'print-js'
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getTranskip, cetTranskip } from '@/services/khs.service'
export default {
  name: 'Transkip',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      dataNil: [],
      ipk: 0,
      profil: users,
      loadData: false,
      loadSubmit: false,
      fullPage: true,
      errorData: {}
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadData || this.loadSubmit))
      return load
    },
    jumlahSKS: function () {
      var totKredit = 0
      const entriMHS = this.dataNil
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    }
  },
  mounted () {
    this.getData()
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '293px',
      alwaysVisible: false
    })
  },
  methods: {
    async getData () {
      this.loadData = true
      const params = {
        id_reg_pd: this.profil.id_reg_pd,
        th_smtmasuk: this.profil.id_smt_masuk
      }
      getTranskip(params).then(response => {
        if (response.error_code === 200) {
          this.dataNil = response.records
          this.ipk = response.ipk
        }
        this.loadData = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadData = false
      })
    },
    async printTranskip () {
      this.loadSubmit = true
      cetTranskip({ reg: this.profil.id_reg_pd }).then((response) => {
        printJS(window.URL.createObjectURL(new Blob([response])))
        this.loadSubmit = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadSubmit = false
      })
    },
    async downloadTranskip () {
      this.loadSubmit = true
      cetTranskip({ reg: this.profil.id_reg_pd }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'Transkip ' + this.profil.username + '.pdf')
        document.body.appendChild(fileLink)

        fileLink.click()
        this.loadSubmit = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadSubmit = false
      })
    }
  }
}
</script>
